<!--
 * @Description: 场景信息管理
 * @Author: ChenXueLin
 * @Date: 2021-09-01 09:58:42
 * @LastEditTime: 2022-07-15 15:55:53
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="corpId">
              <e6-vr-select
                v-model="searchForm.corpId"
                :data="corpList"
                :loading="corpListLoaing"
                placeholder="客户名称"
                title="客户名称"
                @filterChange="corpHandleFilter"
                :props="{
                  id: 'corpId',
                  label: 'corpName'
                }"
                virtual
                clearable
                remote
                :is-title="true"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="attrId">
              <e6-vr-select
                v-model="searchForm.attrId"
                :data="corpAttrEnum"
                placeholder="客户属性"
                title="客户属性"
                :props="{
                  id: 'id',
                  label: 'attrValueDesc'
                }"
                multiple
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="sceneName">
              <el-input
                v-model="searchForm.sceneName"
                placeholder="场景名称"
                title="场景名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="sceneType">
              <e6-vr-select
                v-model="searchForm.sceneType"
                :data="sceneTypeEnum"
                placeholder="场景类型"
                title="场景类型"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="equipCode">
              <el-input
                v-model="searchForm.equipCode"
                placeholder="设备编号"
                title="设备编号"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="firstClassId">
              <e6-vr-select
                v-model="searchForm.firstClassId"
                :loading="firstClassLoading"
                :data="firstClassEnum"
                placeholder="商品分类"
                title="商品分类"
                @change="firstClassChange"
                @clear="firstClassChange"
                :props="{
                  id: 'firstClassId',
                  label: 'firstClassName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="secondClassId">
              <e6-vr-select
                v-model="searchForm.secondClassId"
                :data="secondClassEnum"
                :loading="secondClassIdLoading"
                @change="secondClassChange"
                @clear="secondClassChange"
                placeholder="商品开票名称"
                title="商品开票名称"
                :props="{
                  id: 'secondClassId',
                  label: 'secondClassName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="thirdClassId">
              <e6-vr-select
                v-model="searchForm.thirdClassId"
                :data="thirdClassEnum"
                :loading="thirdClassLoading"
                placeholder="商品名称"
                title="商品名称"
                :props="{
                  id: 'thirdClassId',
                  label: 'thirdClassName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="virtualType">
              <e6-vr-select
                v-model="searchForm.virtualType"
                :data="typeIdEnum"
                placeholder="商品类型"
                title="商品类型"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="fixedOrMoved">
              <e6-vr-select
                v-model="searchForm.fixedOrMoved"
                :data="fixedOrMovedEnum"
                placeholder="固定/移动"
                title="固定/移动"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="terStatus">
              <e6-vr-select
                v-model="searchForm.terStatus"
                :data="terminalStatusEnum"
                placeholder="终端状态"
                title="终端状态"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="commids">
              <el-input
                v-model="searchForm.commids"
                placeholder="中心识别码"
                title="中心识别码"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="installType">
              <e6-vr-select
                v-model="searchForm.installType"
                :data="equipInstallTypeEnum"
                placeholder="安装类型"
                title="安装类型"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="realNo">
              <el-input
                v-model="searchForm.realNo"
                placeholder="SIM卡号"
                title="SIM卡号"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="createdBy">
              <el-input
                v-model="searchForm.createdBy"
                placeholder="创建人"
                title="创建人"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="engineer">
              <el-input
                v-model="searchForm.engineer"
                placeholder="工程师"
                title="工程师"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="salesman">
              <el-input
                v-model="searchForm.salesman"
                placeholder="业务员"
                title="业务员"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--2" prop="installTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="安装时间"
                ref="effectiveDatePicker"
                v-model="searchForm.installTime"
                title="安装时间"
                :picker-options="pickerOptions('searchForm.installTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="安装时间（始）"
                end-placeholder="安装时间（止）"
              ></e6-date-picker>
            </el-form-item>

            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="创建时间"
                ref="effectiveDatePicker"
                v-model="searchForm.createTime"
                title="创建时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="创建时间（始）"
                end-placeholder="创建时间（止）"
              ></e6-date-picker>
            </el-form-item>

            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-export_line" title="导出" @click="exportData"></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          ref="tableList"
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
        >
          <!-- <el-table-column
            type="selection"
            width="40"
            fixed="left"
          ></el-table-column> -->
          <el-table-column
            :show-overflow-tooltip="column.fieldName !== 'attrName'"
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
            <template slot-scope="{ row }">
              <span v-if="index < 10 && !row.isDisplayPublicfield"></span>
              <span
                v-else-if="
                  column.fieldName === 'installGroup' && row.installGroup
                "
              >
                {{ row[column.fieldName].secondClassName }}
              </span>
              <el-button
                v-else-if="column.fieldName === 'regName'"
                type="text"
                @click="openWordRecord(row)"
                >{{ row[column.fieldName] }}</el-button
              >
              <el-tooltip
                v-else-if="column.fieldName === 'attrName'"
                class="item"
                effect="dark"
                :content="row.attrName"
                placement="right"
              >
                <div class="ellipsis">
                  {{ row.attrName }}
                </div>
              </el-tooltip>
              <span v-else>{{ row[column.fieldName] }} </span>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                v-if="scope.row.virtualType == 1 || !!scope.row.disDetailBtn"
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <sceneInfoWordRecord
      ref="sceneInfoWordRecord"
      v-if="sceneInfoWordRecordVisible"
    ></sceneInfoWordRecord>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import goodsClassInteractions from "@/mixins/commomFormInteractions/goodsClassInteractions";
import { exportData } from "@/utils/download";
import {
  getSceneList,
  exportSceneList,
  findDownList,
  getCorpAttrDownList
} from "@/api";
import { printError } from "@/utils/util";

import sceneInfoWordRecord from "@/views/OMS/equipSceneManage/sceneInfoManage/sceneInfoWordRecord";
const nowTime = new Date().getTime(); // 打开页面时的时间戳
export default {
  name: "sceneInfo",
  components: { sceneInfoWordRecord },
  mixins: [listPage, listPageReszie, base, goodsClassInteractions],
  data() {
    return {
      sceneInfoWordRecordVisible: false, // 场景业务记录组件加载开关
      total: 0,
      searchForm: {
        pageIndex: 1,
        pageSize: 20,
        corpId: "", //客户ID
        attrId: [], //客户属性
        sceneName: "", //场景名称
        sceneType: "", //场景类型
        equipCode: "", //设备编号
        thirdClassId: "", //商品名称
        firstClassId: "", //商品分类
        secondClassId: "", //商品开票名称
        virtualType: "1", //商品类型
        fixedOrMoved: "", //固定/移动
        terStatus: "", //终端状态
        commids: "", //中心识别码
        installType: "", //安装类型
        realNo: "", //sim卡号
        createdBy: "", //创建人
        engineer: "", //工程师
        salesman: "", //业务员
        installTime: [], //安装时间
        startInstallTime: "", //安装开始时间
        endInstallTime: "", //安装结束时间
        createTime: [nowTime - 2592000000], //创建时间
        startCreatedTime: nowTime - 2592000000, //创建开始时间
        endCreatedTime: "", //创建结束时间
        applyStatus: "" // 审核状态
      },
      fixedOrMovedEnum: [], // 移动/固定枚举
      typeIdEnum: [], // 商品类型枚举(主机/配件)
      equipInstallTypeEnum: [], // 安装类型枚举
      terminalStatusEnum: [], // 终端状态枚举
      sceneTypeEnum: [], // 场景类型枚举
      corpAttrEnum: [],
      columnData: [
        {
          fieldName: "rowNum",
          display: true,
          fieldLabel: "序号",
          width: 60,
          disabled: false,
          fixed: "left",
          align: "center"
        },
        {
          fieldName: "corpName",
          display: true,
          fieldLabel: "客户名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "attrName",
          display: true,
          fieldLabel: "客户属性",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "sceneName",
          display: true,
          fieldLabel: "场景名称",
          width: 130,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "regName",
          display: true,
          fieldLabel: "系统场景名称",
          width: 130,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "customName",
          display: true,
          fieldLabel: "自定义场景名称",
          width: 130,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "sceneTypeName",
          display: true,
          fieldLabel: "场景类型",
          width: 80,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "installGroupName",
          display: true,
          fieldLabel: "安装组合",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "terStatusName",
          display: true,
          fieldLabel: "终端状态",
          width: 80,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "checkResultName",
          display: true,
          fieldLabel: "审核状态",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "equipCode",
          display: true,
          fieldLabel: "设备编号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "thirdClassName",
          display: true,
          fieldLabel: "商品名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "firstClassName",
          display: true,
          fieldLabel: "商品分类",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "secondClassName",
          display: true,
          fieldLabel: "商品开票名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "virtualTypeName",
          display: true,
          fieldLabel: "商品类型",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "fixedOrMovedName",
          display: true,
          fieldLabel: "固定/移动",
          width: 80,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "realNo",
          display: true,
          fieldLabel: "SIM卡号",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "commids",
          display: true,
          fieldLabel: "中心识别码",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "installTimeStr",
          display: true,
          fieldLabel: "安装/发货时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "installTypeName",
          display: true,
          fieldLabel: "安装类型",
          width: 80,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "salesman",
          display: true,
          fieldLabel: "业务员",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "engineerName",
          display: true,
          fieldLabel: "工程师",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdBy",
          display: true,
          fieldLabel: "创建人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdTimeStr",
          display: true,
          fieldLabel: "创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "remark",
          display: true,
          fieldLabel: "备注",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: []
    };
  },
  computed: {},
  watch: {
    // 创建开始时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.startCreatedTime = val[0] || "";
        this.searchForm.endCreatedTime = val[1] || "";
      }
    },
    // 安装时间
    "searchForm.installTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.installTime = this.searchForm.installTime.reverse();
        }
        this.searchForm.startInstallTime = val[0] || "";
        this.searchForm.endInstallTime = val[1] || "";
      }
    }
  },
  created() {
    this.queryList();
    this.initData();
    this.loadCorpData();
  },
  mounted() {
    this.setTableScrollDOM();
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    //获取场景列表
    async queryList() {
      const {
        startCreatedTime,
        startInstallTime,
        endInstallTime,
        corpId,
        sceneName,
        equipCode
      } = this.searchForm;
      let queryFlag = startCreatedTime;
      queryFlag = queryFlag || (startInstallTime && endInstallTime);
      queryFlag = queryFlag || corpId || sceneName || equipCode;
      if (!queryFlag) {
        this.$message({
          type: "warning",
          duration: 4000,
          message:
            "客户名称、场景名称、设备编号、安装时间、创建时间必须选择其中之一作为查询条件！"
        });
        return;
      }
      try {
        this.loading = true;
        let res = await getSceneList(this.searchForm);
        let tableData = this.getFreezeResponse(res, {
          path: "data.array"
        });
        // 洗一下数据
        this.tableData = this.getTableDataAddedIsDisplayPublicfield(tableData);
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
        this.elementTableDoLayout();
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 洗一下数据 --> 是否展示公共字段
    getTableDataAddedIsDisplayPublicfield(list) {
      if (!list || !list.length) return;
      // let list = JSON.parse(JSON.stringify(data));
      list.map(item => {
        item.disDetailBtn = false;
        item.isDisplayPublicfield = false;
      });
      let obj = list.reduce((pre, cur) => {
        (pre[cur.rowNum] || (pre[cur.rowNum] = [])).push(cur);
        return pre;
      }, []);
      let tableData = [];
      for (let key in obj) {
        let arr = obj[key].length ? obj[key] : [];
        let hasMainEquip = arr.some(ele => ele.virtualType == 1);
        if (arr.length) arr[0].isDisplayPublicfield = true;
        if (!hasMainEquip && arr.length) {
          // console.log(arr, "728~~~");
          arr[0].disDetailBtn = true;
        }
        tableData = [...tableData, ...arr];
      }
      // console.log(tableData);
      return tableData;
      // let index = -1;
      // let firstequip = list[0];
      // let hasMainEquip = false;
      // return list.map((item, itemIndex) => {
      //   item.isDisplayPublicfield = index !== item.rowNum;
      //   // 如果该组合没有主机 则在第一个配件显示详情按钮
      //   if (index !== item.rowNum || itemIndex === list.length - 1) {
      //     // console.log(itemIndex, !hasMainEquip, "===");
      //     this.$set(firstequip, "disDetailBtn", !hasMainEquip);
      //     firstequip = item;
      //     hasMainEquip = false;
      //   }
      //   if (item.virtualType === 1) {
      //     hasMainEquip = true;
      //   }

      //   index = item.rowNum;
      //   return item;
      // });
    },
    // 打开场景业务历史记录
    openWordRecord(row) {
      this.sceneInfoWordRecordVisible = true;
      this.$nextTick(() => {
        this.$refs.sceneInfoWordRecord.init(row);
      });
    },
    // 导出
    exportData() {
      exportData(this, exportSceneList);
    },
    // 获取基础信息(枚举)
    async initData() {
      try {
        let promiseList = [
          findDownList([
            "fixedOrMoved",
            "typeId",
            "equipInstallType",
            "terminalStatus",
            "sceneType"
          ]),
          getCorpAttrDownList({})
        ];
        let [findDownListRes, corpAttrRes] = await Promise.all(promiseList);
        this.fixedOrMovedEnum = this.getFreezeResponse(findDownListRes, {
          path: "data.fixedOrMoved"
        });
        this.typeIdEnum = this.getFreezeResponse(findDownListRes, {
          path: "data.typeId"
        });
        this.equipInstallTypeEnum = this.getFreezeResponse(findDownListRes, {
          path: "data.equipInstallType"
        });
        this.terminalStatusEnum = this.getFreezeResponse(findDownListRes, {
          path: "data.terminalStatus"
        });
        this.sceneTypeEnum = this.getFreezeResponse(findDownListRes, {
          path: "data.sceneType"
        });
        this.corpAttrEnum = this.getFreezeResponse(corpAttrRes, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      }
    },
    // 客户列表 远程搜索
    corpHandleFilter: _.debounce(async function(val) {
      if (val) {
        this.loadCorpData(val);
        this.searchForm.corpId = "";
      }
    }, 300),
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "终端详情",
          id: 1
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val, row) {
      console.log(val, row);
      if (val.id == 1) {
        this.routerPush({
          name: "equipSceneManage/sceneInfoDetail",
          params: {
            refresh: true,
            origin: this.$route.name,
            vehicleId: row.vehicleId,
            terminalId: row.terminalId,
            thirdClassName: row.thirdClassName,
            thirdClassId: row.thirdClassId,
            sceneId: row.sceneId
          }
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .edit-customer-dialog {
  .el-form {
    padding: 20px 20px 0;
    .el-input {
      width: 350px;
    }
  }
}
</style>
