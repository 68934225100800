<!--
 * @Description: 场景业务历史记录
 * @Author: LiangYiNing
 * @Date: 2022-02-23 09:39:08
 * @LastEditTime: 2022-07-01 09:26:04
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    title="场景相关业务历史记录"
    :visible.sync="dialogVisible"
    width="900px"
    :before-close="handleClose"
    :close-on-click-modal="false"
    v-loading="loading"
    :element-loading-background="loadingBackground"
    custom-class="edit-job-dialog edit-wrapper"
  >
    <div class="edit-title">系统场景名称：{{ regName }}</div>
    <el-table border height="400px" :data="tableData" highlight-current-row>
      <el-table-column
        label="序号"
        width="50"
        fixed="left"
        header-align="center"
        align="center"
      >
        <template slot-scope="scope">
          <span>
            {{ scope.$index + 1 }}
          </span>
        </template>
      </el-table-column>
      <!-- 序号 end -->
      <el-table-column
        v-for="(column, index) in columnData"
        :show-overflow-tooltip="column.fieldName === 'remark' ? false : true"
        :key="index"
        :prop="column.fieldName"
        :label="column.fieldLabel"
        :min-width="column.width"
        :fixed="column.fixed"
        :align="column.align"
        header-align="center"
      >
        <template slot-scope="{ row }">
          <span v-if="column.fieldName === 'commodity'">
            <el-button type="text" @click="openGoodsInfo(row)">
              {{ row[column.fieldName] }}
            </el-button>
          </span>
          <span v-else>{{ row[column.fieldName] }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="remark"
        label="备注"
        :min-width="160"
        :fixed="false"
        align="center"
        header-align="center"
      >
        <template slot-scope="{ row }">
          <el-tooltip placement="top-start" effect="dark" :content="row.remark">
            <div>
              {{
                row.remark.length > 30
                  ? row.remark.substr(0, 30) + "..."
                  : row.remark
              }}
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>
<script>
import base from "@/mixins/base";
import { printError } from "@/utils/util";
import { getSceneBussuessHis } from "@/api";
export default {
  name: "sceneInfoWordRecord",
  mixins: [base],
  data() {
    return {
      dialogVisible: true,
      loading: false,
      regName: "",
      tableData: [],
      columnData: [
        {
          fieldName: "operTime",
          display: true,
          fieldLabel: "时间",
          width: 100,
          disabled: false,
          fixed: "left",
          align: "center"
        },
        {
          fieldName: "bssType",
          display: true,
          fieldLabel: "业务类型",
          width: 80,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "updateUser",
          display: true,
          fieldLabel: "相关业务人员",
          width: 80,
          disabled: false,
          fixed: false,
          align: "center"
        }
        // {
        //   fieldName: "remark",
        //   display: true,
        //   fieldLabel: "备注",
        //   width: 160,
        //   disabled: false,
        //   fixed: false,
        //   align: "left"
        // }
      ]
    };
  },
  computed: {
    dynamicRules() {
      let editFormRules = {};
      return editFormRules;
    }
  },
  methods: {
    init(row) {
      this.dialogVisible = true;
      this.regName = row.regName;
      this.queryList(row);
    },
    handleClose() {
      this.tableData = [];
      this.dialogVisible = false;
    },

    //获取列表数据
    async queryList(data = {}) {
      let { terminalId, regName } = data;
      if (!terminalId || !regName) {
        return;
      }
      try {
        this.loading = true;
        let res = await getSceneBussuessHis({
          terminalId,
          regName
        });
        this.tableData = this.getFreezeResponse(res, {
          path: "data.hisList"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .edit-job-dialog {
  height: auto;
  .edit-title {
    padding: 0px;
  }
  .el-dialog__body {
    padding: 20px 20px 20px 20px;
  }
}
</style>
